
import { defineComponent, ref } from 'vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useRoute, useRouter } from 'vue-router'
import ApiService from '@/core/services/ApiService'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import Submissions from '@/costumComponents/classe/exercices/submissions.vue'
import { useStore } from 'vuex'
import { AxiosRequestConfig } from 'axios'
import { event } from 'vue-gtag'

interface Exercise {
  _id: string
  title: string
  module: string
  subject: string
  createdAt: string
  updatedAt: string
  deadline?: string
  marked: boolean
  desc: string
  files: string[]
  photos: string[]
  trimester: string
  students: []
  status: string
  reviewDate: string
  reviewNote: string
}

interface Comment {
  _id: string
  createdAt: string
  updatedAt: string
  fullName: string
  photo: string
  text: string
  user: { _id: string; role: string }
}

export default defineComponent({
  name: 'exercise-details',
  components: {
    Submissions,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const user = store.getters.currentUser
    const fullName = user.employee.firstName + ' ' + user.employee.lastName

    const comments = ref<Comment[]>([])

    const homeworkID = route.params.hid
    const classID = route.params.id

    const exercise = ref<Exercise>({
      subject: '',
      _id: '',
      title: '',
      module: '',
      desc: '',
      createdAt: '',
      updatedAt: '',
      deadline: '',
      marked: false,
      files: [],
      photos: [],
      trimester: '',
      students: [],
      status: 'validated',
      reviewDate: '',
      reviewNote: '',
    })

    const studentList = ref<[]>([])
    const getStudents = (students: []) => {
      const sy = window.localStorage.getItem('activeSchoolarYear')
      let match = {}
      match[`schoolarYearsHistory.${sy}`] = classID
      ApiService.post('/students/filter', {
        query: { status: 'active' },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: '$schoolarYearsHistory.' + sy,
                  to: 'objectId',
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data.filter((s) =>
            students.find((id) => id == s._id)
          )
        })
        .catch((e) => console.log(e))
    }

    ApiService.get(`/lms/homework/${homeworkID}`)
      .then(({ data }) => {
        exercise.value = {
          subject: data.subject.name,
          _id: data._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          deadline: data.deadline,
          marked: data.marked,
          files: data.resources.filter(
            (f) => !['png', 'jpg'].includes(f.split('.')[1])
          ),
          photos: data.resources
            .filter((f) => ['png', 'jpg'].includes(f.split('.')[1]))
            .map(
              (p: string) => store.getters.serverConfigUrl.base_url + '/' + p
            ),
          trimester: data.trimester ? data.trimester : '0',
          students: data.students,
          status: data.status,
          reviewDate: data.reviewDate,
          reviewNote: data.reviewNote,
        }
        if (exercise.value.students && exercise.value.students.length != 0)
          getStudents(exercise.value.students)
      })
      .catch((e) => {
        console.log(e)
      })

    ApiService.get(`/lms/comment/homework/${homeworkID}`)
      .then(({ data }) => {
        data.forEach((comment: Comment) => {
          comment.photo = comment.photo
            ? store.getters.serverConfigUrl.base_url +
              '/' +
              comment.photo.replace(/\\/, '/')
            : 'media/avatars/blank.png'
          comments.value.push(comment)
        })
      })
      .catch((e) => {
        console.log(e)
      })

    const confirmDelete = () => {
      Swal.fire({
        title: t('course.confirmDeleteExercise'),
        text: t('course.deleteExerciseWarn'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: t('course.acceptDelete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('/lms/homework/' + homeworkID)
            .then(() => {
              event('Delete exercice', {
                event_category: 'Exercice',
                event_label: 'Classe profile',
                value: 1,
              })

              Swal.fire(
                t('course.deleted'),
                t('course.deletedExerciseInfo'),
                'success'
              ).then(() => {
                router.push(`/classe/${classID}/exercices`)
              })
            })
            .catch((e) => console.log(e))
        }
      })
    }

    const getFileIconName = (file: string): string => {
      const type = file.split('.')[1]
      if (
        [
          'pdf',
          'png',
          'jpg',
          'ppt',
          'xls',
          'txt',
          'gif',
          'doc',
          'pptx',
          'xlsx',
          'docx',
          'zip',
          'mkv',
          'avi',
          'mp4',
          'mp3',
        ].includes(type)
      )
        return type
      return 'file'
    }

    const getFileLink = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + '/' + file.replace(/\\/g, '//')
      )
    }

    const getShortFileName = (file: string): string => {
      const t = file.indexOf('--')
      const fileName = file.substring(t == -1 ? 0 : t + 2)
      if (fileName.length > 20)
        return '...' + fileName.substring(fileName.length - 20, fileName.length)
      return fileName
    }

    const commentText = ref<string>('')

    const sendComment = (): void => {
      ApiService.put('/lms/comment/', {
        homework: homeworkID,
        teacher: user._id,
        text: commentText.value,
      } as AxiosRequestConfig)
        .then(({ data }) => {
          event('Add comment', {
            event_category: 'Exercice',
            event_label: 'Classe profile',
            value: 1,
          })
          data.fullName = user.employee.firstName + ' ' + user.employee.lastName
          user.photo = user.employee.photo
            ? store.getters.serverConfigUrl.base_url +
              '/' +
              user.employee.photo.replace(/\\/, '/')
            : 'media/avatars/blank.png'
          data.user = {
            role: 'teacher',
            _id: user._id,
          }
          comments.value.push(data)
          commentText.value = ''
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const deleteComment = (id: string): void => {
      Swal.fire({
        title: t('comment.confirmDelete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: t('course.acceptDelete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(`/lms/comment/${id}`)
            .then(() => {
              event('Delete comment', {
                event_category: 'Exercice',
                event_label: 'Classe profile',
                value: 1,
              })
              comments.value = comments.value.filter(
                (comment) => comment._id != id
              )
              Swal.fire({
                text: t('comment.deleted'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: t('course.okay'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
            .catch((e) => {
              console.log(e)
              Swal.fire({
                text: t('course.errorText'),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: t('course.tryAgain'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              })
            })
        }
      })
    }

    return {
      t,
      classID,
      homeworkID,
      exercise,
      confirmDelete,
      moment,
      getFileIconName,
      getFileLink,
      getShortFileName,
      comments,
      fullName,
      sendComment,
      deleteComment,
      commentText,
      studentList,
    }
  },
})
