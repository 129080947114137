
import ApiService from '@/core/services/ApiService'
import moment from 'moment'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as Yup from 'yup'
import { AxiosRequestConfig } from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useStore } from 'vuex'
import { event } from 'vue-gtag'

interface Submission {
  id: string
  photo?: string
  fullname: string
  createdAt: string
  files?: string[]
  answer?: string
  color: string
  status: string
  note?: number
  currentNote?: number
}

export default defineComponent({
  name: 'exercise-submissions',
  props: {
    homework: String,
    marked: Boolean,
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore()

    const { t } = useI18n()
    const buttonNoteRef = ref<null | HTMLButtonElement>(null)
    const submissionUpdate = ref<boolean>(false)

    const list = ref<Submission[]>([])
    const selectedItem = ref<Submission>({
      id: '',
      fullname: '',
      createdAt: '',
      color: '',
      status: '',
    })

    const validationSchema = Yup.object().shape({
      note: Yup.number()
        .transform((value) => (isNaN(value) ? null : value))
        .min(0, t('course.noteRule'))
        .max(20, t('course.noteRule'))
        .nullable(true),
    })

    const getPhotoURL = (photo: string) => {
      return (
        store.getters.serverConfigUrl.base_url + '/' + photo.replace(/\\/g, '/')
      )
    }

    ApiService.get(`/lms/homework/${props.homework}/submissions`)
      .then(({ data }) => {
        data.forEach((submission) => {
          const sb: Submission = {
            id: submission._id,
            fullname:
              submission.student.firstName + ' ' + submission.student.lastName,
            photo: submission.student.photo,
            files: submission.files,
            answer: submission.answer,
            note: submission.note,
            currentNote: submission.note,

            createdAt: submission.createdAt,
            color: 'success',
            status: 'submitted',
          }
          if (submission.student.photo)
            sb.photo = getPhotoURL(submission.student.photo)
          list.value.push(sb)
        })
      })
      .catch((e) => {
        console.log(e)
      })

    const route = useRoute()
    const students = ref<number>(0)

    ApiService.get('/teacher/classroomstat/' + route.params.id)
      .then(({ data }) => {
        students.value = data.total
      })
      .catch((e) => console.log(e))

    const saveNote = () => {
      if (buttonNoteRef.value) {
        buttonNoteRef.value.disabled = true
        buttonNoteRef.value.setAttribute('data-kt-indicator', 'on')
      }

      ApiService.patch(
        `/lms/homework/submissionNote/${selectedItem.value.id}`,
        {
          note: selectedItem.value.currentNote,
        } as AxiosRequestConfig
      )
        .then(() => {
          event('Add mark submission', {
            event_category: 'Exercise',
            event_label: 'Class profile',
            value: 1,
          })

          if (buttonNoteRef.value) {
            buttonNoteRef.value.disabled = false
            buttonNoteRef.value.removeAttribute('data-kt-indicator')
          }
          submissionUpdate.value = true
          selectedItem.value.note = selectedItem.value.currentNote
          setTimeout(() => {
            submissionUpdate.value = false
          }, 3000)
        })
        .catch((e) => {
          Swal.fire({
            text: t('course.errorText'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: t('course.tryAgain'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          })
          buttonNoteRef.value!.disabled = false
          buttonNoteRef.value?.removeAttribute('data-kt-indicator')
          console.log(e)
        })
    }

    const getFileIconName = (file: string): string => {
      const type = file.split('.')[1]
      if (
        [
          'pdf',
          'png',
          'jpg',
          'ppt',
          'xls',
          'txt',
          'gif',
          'doc',
          'pptx',
          'xlsx',
          'docx',
          'zip',
          'mkv',
          'avi',
          'mp4',
          'mp3',
        ].includes(type)
      )
        return type
      return 'file'
    }

    const getFileLink = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + '/' + file.replace(/\\/g, '//')
      )
    }

    const getShortFileName = (file: string): string => {
      const t = file.indexOf('--')
      const fileName = file.substring(t == -1 ? 0 : t + 2)
      if (fileName.length > 20)
        return '...' + fileName.substring(fileName.length - 20, fileName.length)
      return fileName
    }

    const perPage = 7

    const currentPage = ref<number>(1)

    const totalPages = computed(() => Math.ceil(list.value.length / perPage))

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1
      const endIndex = startIndex + perPage - 1
      return list.value.slice(startIndex - 1, endIndex)
    })

    return {
      buttonNoteRef,
      list,
      students,
      selectedItem,
      t,
      validationSchema,
      saveNote,
      getFileIconName,
      getFileLink,
      getShortFileName,
      moment,
      submissionUpdate,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
    }
  },
})
